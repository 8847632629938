import React from "react";
import Table from "../components/Table";
import ActionHandler from "../services/data-handler";
import {
  ActionTypes,
  ACTION_HANDLER_TYPE,
  Pattern,
} from "../shared/common/constant";
import { DateFormat } from "../shared/Utils";
import { useParams } from "react-router-dom";
import { Modal } from "../shared/Modal";
import { showAlert } from "../shared/Alert";
import Form from "../shared/Form";
import { DateConfig } from "../configs/date";
import {
  toggleBtnLoadingOff,
  toggleBtnLoadingOn,
} from "../services/app.service";

const CustomerDetails = () => {
  const dataRef = React.useRef([]);
  const { key } = useParams();
  const [data, setData] = React.useState([]);
  const [licenseData, setLicenseData] = React.useState();
  const [activityData, setActivityData] = React.useState([]);
  const [autoUpdateModalOpen, setAutoUpdateModalOpen] = React.useState(false);
  const [maxActModalOpen, setMaxActModalOpen] = React.useState(false);
  const [checked, setChecked] = React.useState();
  const [maxActivationValue, setMaxActivationValue] = React.useState();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = React.useState(false);
  const [isProductModalOpen, setIsProductModalOpen] = React.useState(false);
  const [eventCategory, setEventCategory] = React.useState();
  const [newDetailsValues, setNewDetailsValues] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: (row) => DateFormat(row.createdAt, true),
      },
      {
        Header: "Activity",
        accessor: "activity",
      },
      {
        Header: "Source",
        accessor: "source",
      },
      {
        Header: "Fingerprint",
        accessor: "fingerprint",
      },
      {
        Header: "Result",
        accessor: "result",
      },
      {
        Header: "Result Message",
        accessor: "message",
      },
    ],
    []
  );
  React.useEffect(() => {
    dataRef.current = [...data];
  }, [data]);

  React.useEffect(() => {
    getActivityDetails();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    ActionHandler(ACTION_HANDLER_TYPE.GET_LICENSE_DETAILS, {
      key,
    }).then((licenseDetails) => {
      setNewDetailsValues(licenseDetails);
      setLicenseData(licenseDetails);
      setMaxActivationValue(licenseDetails.maxActivation);
    });
    // eslint-disable-next-line
  }, []);

  const getActivityDetails = async () => {
    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.GET_ACTIVITY_DETAILS,
      { key }
    );

    const activityDetails = response.activityDetails;
    activityDetails.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setActivityData(activityDetails);
  };

  const modifyAutoUpdate = async (licenseData) => {
    const newLicenseData = licenseData;

    newLicenseData.autoUpdateEnabled = !Boolean(licenseData.autoUpdateEnabled);

    const updateAutoUpdate = {
      key: newLicenseData.licenseKey,
      autoUpdateEnabled: newLicenseData.autoUpdateEnabled,
    };

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_AUTO_UPDATE,
      {
        body: updateAutoUpdate,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.key === response.key
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Auto-Update setting changed.",
        });
      }
    }
  };

  const modifyMaxActivation = async (maxActivationValue) => {
    const newLicenseData = licenseData;

    newLicenseData.maxActivation = Number(maxActivationValue);

    const updateMaxActivation = {
      key: newLicenseData.licenseKey,
      maxActivation: newLicenseData.maxActivation,
    };

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_MAX_ACTIVATION,
      {
        body: updateMaxActivation,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Maximum Activation setting changed.",
        });
      }
    }
  };

  const updateTrialExpiry = async (newExpiryDate) => {
    let rowData = licenseData;
    rowData.id = String(rowData.id);
    // convert string to ISO date-time
    rowData.expirationDateTime = new Date(newExpiryDate).toISOString();

    const updateExpirationDate = {
      key: rowData.licenseKey,
      expirationDateTime: rowData.expirationDateTime,
    };

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_EXPIRY_DATE_TIME,
      {
        body: updateExpirationDate,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.key === response.key
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "License key expiration date updated.",
        });
      }
    }

    setIsUpdateModalOpen(false);
  };

  const modifyProductType = async (eventCategory) => {
    const newLicenseData = licenseData;

    newLicenseData.desktopEnabled = Boolean(eventCategory);

    const updateProductType = {
      key: newLicenseData.licenseKey,
      desktopEnabled: newLicenseData.desktopEnabled,
    };

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_PRODUCT_TYPE,
      {
        body: updateProductType,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.key === response.key
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Product Type setting changed.",
        });
      }
    }
  };

  const handleAutoUpdateSubmit = () => {
    const newAutoUpdate = !licenseData.autoUpdateEnabled;
    setChecked(newAutoUpdate);
    modifyAutoUpdate(licenseData);
    setAutoUpdateModalOpen(false);
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      setMaxActivationValue(e.target.value);
    } else if (Pattern.numericRegex.test(e.target.value)) {
      setMaxActivationValue(e.target.value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setMaxActModalOpen(true);
      setMaxActivationValue(maxActivationValue);
    }
  };

  const handleMaxActivationSubmit = (e) => {
    e.preventDefault();
    setMaxActivationValue(maxActivationValue);
    modifyMaxActivation(maxActivationValue);
    setMaxActModalOpen(false);
  };

  const handleEventCategory = (e) => {
    setEventCategory(e.target.value);
  };

  const handleProductTypeSubmit = (e) => {
    setEventCategory(e.target.value);
    modifyProductType(eventCategory);
    setIsProductModalOpen(false);
  };

  const actionHandler = async ({ type, data, event }) => {
    switch (type) {
      case ActionTypes.modalClose:
        if (isUpdateModalOpen) {
          setIsUpdateModalOpen(false);
        }
        break;
      case ActionTypes.formSubmit:
        toggleBtnLoadingOn();
        if (data) {
          // convert string to ISO date-time
          data.expirationDateTime = new Date(
            data.expirationDateTime
          ).toISOString();
          updateTrialExpiry(data.expirationDateTime);
          toggleBtnLoadingOff();
        }
        break;
      default:
        return;
    }
  };

  const showDetails = [
    {
      label: "Name",
      detailsValues: [
        newDetailsValues.firstName && newDetailsValues.lastName
          ? newDetailsValues.firstName + " " + newDetailsValues.lastName
          : newDetailsValues.firstName,
      ],
    },
    { label: "Email", detailsValues: [newDetailsValues.email] },
    { label: "Key", detailsValues: [newDetailsValues.licenseKey] },
    {
      label: "Type",
      detailsValues: [String(newDetailsValues.type).toUpperCase()],
    },
    {
      label: "Product Type",
      detailsValues: [
        newDetailsValues.desktopEnabled === true
          ? "Pro"
          : newDetailsValues.desktopEnabled === false
          ? " Lite "
          : " - ",
      ],
    },
    {
      label: "Auto Update",
      detailsValues: [newDetailsValues.autoUpdateEnabled],
    },
    {
      label: "Maximum Activations",
      detailsValues: [newDetailsValues.maxActivation],
    },
    {
      label: "License Key Expiration Date",
      detailsValues: [
        newDetailsValues.expirationDateTime
          ? new Date(newDetailsValues.expirationDateTime).toLocaleDateString(
              "en-CA"
            )
          : "-",
      ],
    },
    {
      label: "Current Activations",
      detailsValues: [newDetailsValues.currentActivation],
    },
    {
      label: "Last Activity",
      detailsValues: [
        newDetailsValues.lastActivityDate
          ? DateFormat(newDetailsValues.lastActivityDate, true)
          : " - ",
      ],
    },
    {
      label: "Cancelled At",
      detailsValues: [
        newDetailsValues.cancelledAt ? newDetailsValues.cancelledAt : " - ",
      ],
    },
  ];

  const handleClick = (value, e) => {
    if (value.label === "Product Type") {
      setIsProductModalOpen(true);
    } else if (value.label === "License Key Expiration Date") {
      setIsUpdateModalOpen(true);
    }
  };

  React.useEffect(() => {
    document.body.style.overflow = "auto";

    return () => {
      document.body.style.overflow = "hidden";
    };
  }, []);

  return (
    <div className="">
      <main>
        <div className="flex flex-col flex-1 overflow-x-hidden">
          {licenseData !== undefined && (
            <div className="py-6 mt-6">
              <div className="px-6 mx-auto">
                <div className="space-y-5 sm:space-y-6">
                  <div className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                    {showDetails.map((value, index) => (
                      <div
                        key={index}
                        className="bg-white border border-gray-200 rounded"
                      >
                        <div className="px-5 py-4">
                          <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                            {value.label}
                          </p>
                          <div className="flex items-center justify-between mt-3">
                            <p
                              className={`font-bold text-gray-900 ${
                                value.label === "Maximum Activations" ||
                                value.label === "Auto Update"
                                  ? "hidden"
                                  : "visible"
                              } ${
                                value.label === "Email" || value.label === "Key"
                                  ? "text-md"
                                  : "text-xl"
                              } ${
                                value.label === "Product Type" ||
                                value.label === "License Key Expiration Date"
                                  ? "cursor-pointer"
                                  : ""
                              }`}
                              onClick={() => handleClick(value)}
                            >
                              {value.detailsValues}
                            </p>

                            <p
                              className={`text-md font-bold text-gray-900 ${
                                value.label === "Auto Update"
                                  ? "visible"
                                  : "hidden"
                              }`}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  checked
                                    ? checked
                                    : licenseData.autoUpdateEnabled
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  setAutoUpdateModalOpen(true);
                                }}
                                className={
                                  "cursor-pointer flex gap-x-2 w-7 h-7 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                                }
                              />
                            </p>

                            <p
                              className={`text-md font-bold text-gray-900 ${
                                value.label === "Maximum Activations"
                                  ? "visible"
                                  : "hidden"
                              }`}
                            >
                              <input
                                type="text"
                                value={maxActivationValue}
                                minLength="1"
                                maxLength="5"
                                onKeyDown={handleKeyDown}
                                onChange={handleChange}
                                className={
                                  "block w-1/2 px-2 py-0 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-lg font-bold text-gray-900 caret-indigo-600"
                                }
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

                    <Modal isOpen={autoUpdateModalOpen}>
                      <div className="text-center my-6">
                        <p>
                          Are you sure you want to change the Auto-Update
                          setting for{" "}
                          {licenseData.firstName && licenseData.lastName
                            ? licenseData.firstName + " " + licenseData.lastName
                            : licenseData.firstName}
                          ?
                        </p>
                        <div className="mt-8 space-x-5">
                          <button
                            type="button"
                            onClick={() => {
                              setChecked(licenseData.autoUpdateEnabled);
                              setAutoUpdateModalOpen(false);
                            }}
                            className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={handleAutoUpdateSubmit}
                            className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </Modal>

                    <Modal isOpen={maxActModalOpen}>
                      <div className="text-center my-6">
                        <p>
                          Are you sure you want to change the Maximum
                          Activations setting for{" "}
                          {licenseData.firstName && licenseData.lastName
                            ? licenseData.firstName + " " + licenseData.lastName
                            : licenseData.firstName}
                          ?
                        </p>

                        <div className="mt-8 space-x-5">
                          <button
                            type="button"
                            onClick={() => {
                              setMaxActivationValue(licenseData.maxActivation);
                              setMaxActModalOpen(false);
                            }}
                            className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={handleMaxActivationSubmit}
                            className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      isOpen={isProductModalOpen}
                      onAction={() => {
                        setIsProductModalOpen(true);
                      }}
                      data={data}
                    >
                      <div className="flex items-center justify-center w-full h-full">
                        <div className="w-full max-w-sm">
                          <p className="text-xl font-bold text-gray-900">
                            Product Type
                          </p>
                          <div className={"my-6"}>
                            <select
                              value={eventCategory}
                              onChange={handleEventCategory}
                            >
                              <option value="false">Lite</option>
                              <option value="true">Pro</option>
                            </select>

                            <div className="mt-8 space-x-5 flex justify-end">
                              <button
                                type="button"
                                onClick={() => {
                                  setIsProductModalOpen(false);
                                }}
                                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={handleProductTypeSubmit}
                                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      isOpen={isUpdateModalOpen}
                      onAction={() => {
                        setIsUpdateModalOpen(true);
                      }}
                      data={data}
                    >
                      <div className="flex items-center justify-center w-full h-full">
                        <div className="w-full max-w-sm">
                          <p className="text-xl font-bold text-gray-900">
                            {DateConfig.GenerateDate.title}
                          </p>
                          <Form
                            className={"my-6"}
                            form={DateConfig.GenerateDate}
                            onAction={actionHandler}
                          />
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col flex-1 overflow-x-hidden">
            <div className="py-11">
              <div className="px-6 mx-auto">
                <Table
                  title="Customer Details"
                  columns={columns}
                  data={activityData}
                  scrollY={'hidden'}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CustomerDetails;
