export class Pattern {
	// eslint-disable-next-line
	static emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// eslint-disable-next-line
	static passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
	// eslint-disable-next-line
	static numericRegex = /^[0-9]+(\.?[0-9]+)?$/;
	// eslint-disable-next-line
	static nameRegex = /^(?=.*[A-Za-z0-9]).{1,30}$/;
	// eslint-disable-next-line
	static phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
	// eslint-disable-next-line
	static spaceRegex = /^\S+$/g;
	// eslint-disable-next-line
	static urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
	// eslint-disable-next-line
	static domainRegex = /^[a-zA-Z\.]*$/
	// eslint-disable-next-line
	static specialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
	// eslint-disable-next-line
	static specialCharacterUrlRegex = /[ `!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
}

export class FORM {
	static TYPES = {
		URL: 'url',
		TEXT: 'text',
		DATE: 'date',
		EMAIL: 'email',
		NUMBER: 'number',
		BUTTON: 'button',
		SUBMIT: 'submit',
		PASSWORD: 'password',
		CHECKBOX: 'checkbox',
		FILE: 'file'		
	}
	static ELEMENTS = {
		INPUT: 'input',
		SELECT: 'select',
		BUTTON: 'button',
		INPUT_CHIP: 'input-chip',
		DATE_PICKER: 'datepicker',
		INPUT_CHECKBOX: 'input-checkbox',
		INPUT_FILE: 'input-file',
		TEXTAREA: 'textarea',
		INV:"inv",
		INPUT_CODE: 'input_code',
		DROPDOWN: 'dropdown'
	}
}

export class ActionTypes {
	// Button Types
	static buttonClick = 'buttonClick';

	// Form Types
	static formSubmit = 'formSubmit';
	static forgotPassword = 'forgotPassword';

	// Modal Types
	static modalClose = 'modalClose';

	// Alert Types
	static successAlert = 'successAlert';
	static failAlert = 'failAlert';

	//
	static selectImage = 'selectImage';
}

export const HTTP_METHODS = {
	GET: 'get',
	POST: 'post',
	PATCH: 'PATCH',
	DELETE: 'delete'
}

export const UserRole = [
	{
		name: 'Admin',
		id: 1
	},
	{
		name: 'User',
		id: 2
	}
]

export const StatusKeys = {
	ACTIVATED: "ACTIVATED",
	DEACTIVATED: "DEACTIVATED",
	ARCHIVED: "ARCHIVED"
}

export const alertInterval = 3000;
export const sidebarResponsiveWidth = 60;
export const API_ORIGIN = 'https://dev-download.vodium.com/';

export const EnterprisePortal = {
	DEV :'https://dev-enterprise.vodium.com/',
	TEST : 'https://test-enterprise.vodium.com/',
	PROD : 'https://enterprise.vodium.com/'
}

export const STORAGE = {
	VODIUM_AUTH: 'VODIUM_AUTH'
}

export const ACTION_HANDLER_TYPE = {
	GET_TRIALS: 'GET_TRIALS',
	GET_METRICS: 'GET_METRICS',
	GET_LICENSES: 'GET_LICENSES',
	GET_TRIAL_LICENSES:' GET_TRIAL_LICENSES',
	GET_UNIVERSAL_LICENSES: 'GET_UNIVERSAL_LICENSES',
	GET_LICENSE_DETAILS: 'GET_LICENSE_DETAILS',
	GET_ACTIVITY_DETAILS: 'GET_ACTIVITY_DETAILS',
	GET_ENTERPRISES: 'GET_ENTERPRISES',
	GET_ENTERPRISES_LICENSES: 'GET_ENTERPRISES_LICENSES',
	GET_ENTERPRISES_DETAILS: 'GET_ENTERPRISES_DETAILS',


	CREATE_ENTERPRISE: 'CREATE_ENTERPRISE',
	CREATE_LICENSE_BATCH: 'CREATE_LICENSE_BATCH',
	CREATE_UNIVERSAL_USER:'CREATE_UNIVERSAL_USER',

	MODIFY_LICENSE: 'MODIFY_LICENSE',
	MODIFY_AUTO_UPDATE: 'MODIFY_AUTO_UPDATE',
	MODIFY_MAX_ACTIVATION: 'MODIFY_MAX_ACTIVATION',
	MODIFY_EXPIRY_DATE_TIME: 'MODIFY_EXPIRY_DATE_TIME',
	MODIFY_BRANDING_IMAGE: 'MODIFY_BRANDING_IMAGE',
	MODIFY_ENTERPRISE:'MODIFY_ENTERPRISE',
	MODIFY_ENTERPRISE_EXPIRATION: 'MODIFY_ENTERPRISE_EXPIRATION',
	MODIFY_PRODUCT_TYPE: 'MODIFY_PRODUCT_TYPE',
	
	ACTIVATE_ENTERPRISE: 'ACTIVATE_ENTERPRISE',
	DEACTIVATE_ENTERPRISE: 'DEACTIVATE_ENTERPRISE',
	DELETE_LICENSE: 'DELETE_LICENSE',

	SET_CUSTOM_CLAIMS: 'SET_CUSTOM_CLAIMS',
}