import { API_ORIGIN, HTTP_METHODS } from "../shared/common/constant";
import HttpService from "./http.service";

class LicenseService {
  static getLicenses = async () => {
    try {
      const endpoint = API_ORIGIN + `licenses?source=purchase`;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to get licenses: " + error);
    }
  };

  static getUniversalLicenses = async () => {
    try {
      const endpoint = API_ORIGIN + `licenses?source=universal`;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to get universal users licenses: " + error);
    }
  };

  static getTrialLicenses = async () => {
    try {
      const endpoint = API_ORIGIN + `licenses`;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to get trial licenses: " + error);
    }
  };

  static modifyLicense = async (id, body) => {
    try {
      const endpoint = API_ORIGIN + "licenses/" + id;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.PATCH,
        body
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to modify license: " + error);
    }
  };

  static createLicenseBatch = async (body) => {
    try {
      const endpoint = API_ORIGIN + "licenses:batchCreate";

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.POST,
        body
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to modify license: " + error);
    }
  };

  static modifyAutoUpdate = async (body) => {
    try {
      const endpoint = API_ORIGIN + `licenses/auto-update`;
      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.PATCH,
        body
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to modify auto-update: " + error);
    }
  };

  static modifyMaxActivation = async (body) => {
    try {
      const endpoint = API_ORIGIN + `licenses/max-activation`;
      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.PATCH,
        body
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to modify maximum activation: " + error);
    }
  };

  static modifyExpirationDateTime = async (body) => {
    try {
      const endpoint = API_ORIGIN + `licenses/expiry-date`;
      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.PATCH,
        body
      );
      
      const status = await response.json();
      
      return status;
    } catch (error) {
      throw Error("Failed to modify expiration date and time: " + error);
    }
  };

  static modifyProductType = async (body) => {
    try {
      const endpoint = API_ORIGIN + `licenses/desktop-enabled`;
      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.PATCH,
        body
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to modify product type: " + error);
    }
  };
}

export default LicenseService;
