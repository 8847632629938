/* eslint-disable react/react-in-jsx-scope */
import { Link, NavLink } from 'react-router-dom';
import Logo from '../assets/vodium-logo.png';
import { sidebarResponsiveWidth } from '../shared/common/constant';
import { appLogout, toggleSidebar } from '../services/app.service';

const Sidebar = () => {
  const normalLink =
    'flex items-center py-3 text-sm font-semibold text-gray-900 transition-all duration-200 hover:bg-gray-200 group';
  const activeLink =
    'flex items-center py-3 text-sm font-semibold text-indigo-600 transition-all duration-200 bg-indigo-50 border-l-2 border-l-indigo-900 group';

  const onLogout = () => {
    appLogout();
    toggleSidebar();
  };

  return (
    <div
      id="sidebar"
      className={`absolute transition-all duration-500 w-60 -left-${sidebarResponsiveWidth} z-10 bg-white xl:flex xl:w-72 xl:flex-col xl:fixed xl:inset-y-0 xl:-left-0`}
    >
      <div className="flex flex-col pt-8 overflow-y-auto h-screen">
        <div className="hidden xl:flex items-center flex-shrink-0 pl-8">
          <Link to="/dashboard">
            <img className="w-auto h-10" src={Logo} alt="VODIUM Back Office" />
          </Link>
        </div>

        <div className="flex flex-col justify-between flex-1 h-screen mt-10">
          <div className="space-y-4 mb-10">
            <nav className="flex-1 space-y-2">
              <NavLink
                onClick={toggleSidebar}
                to="/dashboard"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <svg
                  className="flex-shrink-0 w-4 h-4 ml-10 mr-4"
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 7.5L2.75 6M2.75 6L8 0.75L13.25 6M2.75 6V13.5C2.75 13.9142 3.08579 14.25 3.5 14.25H5.75M13.25 6L14.75 7.5M13.25 6V13.5C13.25 13.9142 12.9142 14.25 12.5 14.25H10.25M5.75 14.25C6.16421 14.25 6.5 13.9142 6.5 13.5V10.5C6.5 10.0858 6.83579 9.75 7.25 9.75H8.75C9.16421 9.75 9.5 10.0858 9.5 10.5V13.5C9.5 13.9142 9.83579 14.25 10.25 14.25M5.75 14.25H10.25"
                    stroke="currentColor"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Dashboard
              </NavLink>
            </nav>

            <div>
              <p className="ml-10 text-xs font-semibold tracking-widest text-gray-400 uppercase">
                Customers
              </p>
              <nav className="flex-1 mt-4 space-y-1">
                <NavLink
                  onClick={toggleSidebar}
                  to="/customers/paid"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <svg
                    className="flex-shrink-0 w-4 h-4 ml-10 mr-4"
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.75 13H16.5V11.5C16.5 10.2574 15.4926 9.25 14.25 9.25C13.5333 9.25 12.8949 9.5851 12.4828 10.1072M12.75 13H5.25M12.75 13V11.5C12.75 11.0078 12.6552 10.5378 12.4828 10.1072M5.25 13H1.5V11.5C1.5 10.2574 2.50736 9.25 3.75 9.25C4.46671 9.25 5.10514 9.5851 5.51718 10.1072M5.25 13V11.5C5.25 11.0078 5.34482 10.5378 5.51718 10.1072M5.51718 10.1072C6.07013 8.72577 7.42111 7.75 9 7.75C10.5789 7.75 11.9299 8.72577 12.4828 10.1072M11.25 3.25C11.25 4.49264 10.2426 5.5 9 5.5C7.75736 5.5 6.75 4.49264 6.75 3.25C6.75 2.00736 7.75736 1 9 1C10.2426 1 11.25 2.00736 11.25 3.25ZM15.75 5.5C15.75 6.32843 15.0784 7 14.25 7C13.4216 7 12.75 6.32843 12.75 5.5C12.75 4.67157 13.4216 4 14.25 4C15.0784 4 15.75 4.67157 15.75 5.5ZM5.25 5.5C5.25 6.32843 4.57843 7 3.75 7C2.92157 7 2.25 6.32843 2.25 5.5C2.25 4.67157 2.92157 4 3.75 4C4.57843 4 5.25 4.67157 5.25 5.5Z"
                      stroke="currentColor"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Paid Users
                </NavLink>
                <NavLink
                  onClick={toggleSidebar}
                  to="/customers/trials"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <svg
                    className="flex-shrink-0 w-4 h-4 ml-10 mr-4"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2499 10.25L8.74987 14L5.74987 5.75L13.9999 8.75L10.2499 10.25ZM10.2499 10.25L13.9999 14M4.39105 0.678894L4.97339 2.85223M2.85204 4.97354L0.678711 4.3912M9.46215 2.03772L7.87116 3.62871M3.62859 7.87128L2.0376 9.46227"
                      stroke="currentColor"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Trial Users
                </NavLink>
              </nav>
            </div>

            <div>
              <p className="ml-10 text-xs font-semibold tracking-widest text-gray-400 uppercase">
                Custom
              </p>
              <nav className="flex-1 mt-4 space-y-1">
                <NavLink
                  onClick={toggleSidebar}
                  to="/trials"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <svg
                    className="flex-shrink-0 w-4 h-4 ml-10 mr-4"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.75 13.25V8.75C5.75 7.92157 5.07843 7.25 4.25 7.25H2.75C1.92157 7.25 1.25 7.92157 1.25 8.75V13.25C1.25 14.0784 1.92157 14.75 2.75 14.75H4.25C5.07843 14.75 5.75 14.0784 5.75 13.25ZM5.75 13.25V5.75C5.75 4.92157 6.42157 4.25 7.25 4.25H8.75C9.57843 4.25 10.25 4.92157 10.25 5.75V13.25M5.75 13.25C5.75 14.0784 6.42157 14.75 7.25 14.75H8.75C9.57843 14.75 10.25 14.0784 10.25 13.25M10.25 13.25V2.75C10.25 1.92157 10.9216 1.25 11.75 1.25H13.25C14.0784 1.25 14.75 1.92157 14.75 2.75V13.25C14.75 14.0784 14.0784 14.75 13.25 14.75H11.75C10.9216 14.75 10.25 14.0784 10.25 13.25Z"
                      stroke="currentColor"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Trials
                </NavLink>
              </nav>
            </div>

            <div>
              <p className="ml-10 text-xs font-semibold tracking-widest text-gray-400 uppercase">
                Enterprise
              </p>
              <nav className="flex-1 mt-4 space-y-1">
                <NavLink
                  onClick={toggleSidebar}
                  to="/enterprise/portal"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <svg
                    className="flex-shrink-0 w-4 h-4 ml-10 mr-4"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.25 14.75V2.75C13.25 1.92157 12.5784 1.25 11.75 1.25H4.25C3.42157 1.25 2.75 1.92157 2.75 2.75V14.75M13.25 14.75L14.75 14.75M13.25 14.75H9.5M2.75 14.75L1.25 14.75M2.75 14.75H6.5M5.75 4.24998H6.5M5.75 7.24998H6.5M9.5 4.24998H10.25M9.5 7.24998H10.25M6.5 14.75V11C6.5 10.5858 6.83579 10.25 7.25 10.25H8.75C9.16421 10.25 9.5 10.5858 9.5 11V14.75M6.5 14.75H9.5"
                      stroke="currentColor"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Portal Users
                </NavLink>
                <NavLink
                  onClick={toggleSidebar}
                  to="/enterprise/universal"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <svg
                    className="flex-shrink-0 w-4 h-4 ml-10 mr-4"
                    width="256"
                    height="256"
                    viewBox="0 0 256 256"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="128"
                      cy="128"
                      r="96"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="23"
                    />
                    <path
                      d="M214.2,170.4,159.9,137a7.2,7.2,0,0,0-3.1-1.1l-22.9-3.1a7.8,7.8,0,0,0-8.3,4.7l-13.7,30.7a8,8,0,0,0,1.4,8.7l18.8,20.3a8.2,8.2,0,0,1,2,7L130.2,224"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="20"
                    />
                    <path
                      d="M65,55.6,56,76.8a8,8,0,0,0-.1,5.9l11.5,30.6a7.9,7.9,0,0,0,5.8,5.1L94.6,123a7.8,7.8,0,0,1,5.5,4.3l3.8,7.9a8.2,8.2,0,0,0,7.2,4.5h13.5"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="20"
                    />
                    <path
                      d="M152.5,35.1l9.3,16.8a8.1,8.1,0,0,1-1.6,9.8L133.3,86a7.2,7.2,0,0,1-1.5,1.1l-12.3,6.8a8.6,8.6,0,0,1-3.8,1H94.3a8.1,8.1,0,0,0-7.4,4.9l-8.3,19.7"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="20"
                    />
                  </svg>
                  Universal Key Users
                </NavLink>
              </nav>
            </div>
          </div>

          <div className="pb-4 mt-auto">
            <nav className="flex-1 space-y-1">
              <NavLink
                onClick={toggleSidebar}
                to="/settings"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <svg
                  className="flex-shrink-0 w-4 h-4 ml-10 mr-4"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.74347 2.23798C7.06327 0.920672 8.93673 0.920673 9.25653 2.23798C9.46312 3.08895 10.4381 3.49278 11.1859 3.03714C12.3435 2.33179 13.6682 3.65653 12.9629 4.81414C12.5072 5.56194 12.9111 6.53688 13.762 6.74347C15.0793 7.06327 15.0793 8.93673 13.762 9.25653C12.9111 9.46312 12.5072 10.4381 12.9629 11.1859C13.6682 12.3435 12.3435 13.6682 11.1859 12.9629C10.4381 12.5072 9.46312 12.9111 9.25653 13.762C8.93673 15.0793 7.06327 15.0793 6.74347 13.762C6.53688 12.9111 5.56194 12.5072 4.81414 12.9629C3.65653 13.6682 2.33179 12.3435 3.03714 11.1859C3.49278 10.4381 3.08895 9.46312 2.23798 9.25653C0.920673 8.93673 0.920672 7.06327 2.23798 6.74347C3.08895 6.53688 3.49278 5.56194 3.03714 4.81414C2.33179 3.65653 3.65653 2.33179 4.81414 3.03714C5.56194 3.49278 6.53688 3.08895 6.74347 2.23798Z"
                    stroke="currentColor"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.25 8C10.25 9.24264 9.24264 10.25 8 10.25C6.75736 10.25 5.75 9.24264 5.75 8C5.75 6.75736 6.75736 5.75 8 5.75C9.24264 5.75 10.25 6.75736 10.25 8Z"
                    stroke="currentColor"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Settings
              </NavLink>

              <div
                onClick={onLogout}
                className="flex cursor-pointer items-center py-3 text-sm font-semibold text-gray-900 transition-all duration-200 hover:bg-gray-200 group"
              >
                <svg
                  className="flex-shrink-0 w-4 h-4 ml-10 mr-4"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 10L14.75 7M14.75 7L11.75 4M14.75 7L4.25 7M8.75 10V10.75C8.75 11.9926 7.74264 13 6.5 13H3.5C2.25736 13 1.25 11.9926 1.25 10.75V3.25C1.25 2.00736 2.25736 1 3.5 1H6.5C7.74264 1 8.75 2.00736 8.75 3.25V4"
                    stroke="currentColor"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Logout
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
