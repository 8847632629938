import { ActionTypes, FORM } from "../shared/common/constant";

export const UniversalConfig = {
  tableMenuOptions: [
    {
      id: 1,
      value: "Stripe customer",
    },
    {
      id: 2,
      value: "Subscription details",
    },
    {
      id: 5,
      value: "License Activity details",
    },
  ],

  Form: {
    id: "addUniversalForm",
    title: "Generate new enterprise customer",
    fields: [
      {
        element: FORM.ELEMENTS.INPUT,
        label: "Name",
        name: "name",
        type: FORM.TYPES.TEXT,
        required: true,
        defaultValue: "",
        placeholder: "Name",
        className: "",
      },
      {
        element: FORM.ELEMENTS.DROPDOWN,
        label: "Product Type",
        name: "desktopEnabled",
        type: FORM.TYPES.TEXT,
        required: true,
        defaultValue: "",
        placeholder: "Product Type",
        className: "",
      },
      {
        element: FORM.ELEMENTS.DATE_PICKER,
        label: "Expiration date",
        name: "expirationDateTime",
        type: FORM.TYPES.DATE,
        required: true,
        defaultValue: "",
        placeholder: "Select expiry date",
        className: "",
        options: {
          minDate: new Date(),
        },
      },
      {
        element: FORM.ELEMENTS.INPUT,
        label: "Email",
        name: "email",
        type: FORM.TYPES.EMAIL,
        required: true,
        defaultValue: "",
        placeholder: "email@example.com",
        className: "",
      },
      {
        element: FORM.ELEMENTS.INPUT,
        label: "Max activations",
        name: "maxActivation",
        type: FORM.TYPES.NUMBER,
        required: true,
        defaultValue: "",
        placeholder: "1",
        className: "",
        validation: {
          min: 1,
          max: 99999,
        },
      },
      {
        element: FORM.ELEMENTS.INPUT_CHECKBOX,
        label: "Enable Auto-Update?",
        name: "autoUpdateEnabled",
        type: FORM.TYPES.CHECKBOX,
        required: false,
        defaultValue: true,
        className: "",
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Cancel",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
        type: FORM.TYPES.SUBMIT,
        label: "Generate",
        onActionType: "",
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500",
      },
    ],
  },
};
