import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { app, auth } from "../firebase-config";
import { showAlert } from "../shared/Alert";
import { ActionTypes, ACTION_HANDLER_TYPE } from "../shared/common/constant";
import ActionHandler from "../services/data-handler";

const db = getFirestore(app);

const currentUserState = (callback) => {
  auth.onAuthStateChanged((user) => {
    callback(user);
  },
  (err) => {
    console.error(err)
  })
}

const getUsers = async () => {
  try {
    const users = [];
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach((doc) => {
      users.push(doc.data());
    });
    return users;
  } catch (err) {
    console.error(err);
    showAlert({
      type: ActionTypes.failAlert,
      text: 'Cannot get users. ' + err.message
    })
  }
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const authUser = await signInWithEmailAndPassword(auth, email, password);
    if(authUser) {
      const userQuery = query(collection(db, "users"), where("uid", "==", authUser.user.uid));
      let user;
      const querySnapshot = await getDocs(userQuery);
      querySnapshot.forEach((doc) => {
        user = doc.data();
      });
      showAlert({
        type: ActionTypes.successAlert,
        text: 'You have successfully logged in.'
      })
      return user;
    } else {
      showAlert({
        type: ActionTypes.failAlert,
        text: 'You have entered invalid credentials.'
      });
    }
  } catch (err) {
    console.error(err);
    showAlert({
      type: ActionTypes.failAlert,
      text: 'You have entered invalid credentials.'
    });
  }
};
const registerWithEmailAndPassword = async ({name, email, password, role}) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const newUser = await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      role
    });
    if (role === "1") {
      await ActionHandler(
        ACTION_HANDLER_TYPE.SET_CUSTOM_CLAIMS,
        {
          body: {
            uid: user.uid,
            claims: "role=admin"
          },
        }
      );
    }
    showAlert({
      type: ActionTypes.successAlert,
      text: 'A new user account has been created.'
    })
    return newUser;
  } catch (err) {
    console.error(err);
    showAlert({
      type: ActionTypes.failAlert,
      text: 'User creation failed, please try again. ' + err.message
    });
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    showAlert({
      type: ActionTypes.successAlert,
      text: 'Password reset email has been sent.'
    })
    return true;
  } catch (err) {
    console.error(err);
    showAlert({
      type: ActionTypes.failAlert,
      text: 'You have entered invalid credentials.'
    });
  }
};
const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  currentUserState,
  getUsers,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};