import React from 'react'
import Table from '../components/Table'
import { SettingsConfig } from '../configs/settings'
import { ActionTypes, UserRole } from '../shared/common/constant'
import { Modal } from '../shared/Modal'
import Form from '../shared/Form'
import { getUsers, registerWithEmailAndPassword } from '../services/auth.service'
import { isAdmin, toggleBtnLoadingOff, toggleBtnLoadingOn } from '../services/app.service'
import { connect } from "react-redux"

const Settings = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [usersData, setUsersData] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'User role',
        accessor: 'role',
      }
    ],
    []
  )

  React.useEffect(() => {
    fetchUsersData();
    // eslint-disable-next-line
  }, [])

  const fetchUsersData = () => {
    getUsers().then(users => {
      users.forEach(user => user.role = UserRole.find(role => role.id === Number(user.role))?.name);
      setUsersData(users);
    })
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const actionHandler = async ({ type, data, event }) => {
    switch(type) {
      case ActionTypes.modalClose:
        closeModal();
        break;
      case ActionTypes.formSubmit:
        toggleBtnLoadingOn();
        registerWithEmailAndPassword({name: data.name, email: data.email, password: data.password, role: data.user_role}).then(user => {
          if(user) {
            fetchUsersData();
            closeModal();
          }
          toggleBtnLoadingOff();
        });
        break;
      case ActionTypes.buttonClick:
        openModal();
        break;
      default:
        return;
    }
  }

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="py-11">
          <div className="px-6 mx-auto">
            <Table  title="Settings" columns={columns} data={usersData} button={isAdmin(user) && SettingsConfig.Form.title} onAction={actionHandler}/>
            <Modal
              isOpen={isModalOpen}
              onAction={actionHandler}
            >
              <div className="flex items-center justify-center w-full h-full">
                <div className="w-full max-w-sm">
                  <div className="">
                    <p className="text-xl font-bold text-gray-900">{SettingsConfig.Form.title}</p>
                    <Form className={'my-6'} form={SettingsConfig.Form} onAction={actionHandler} />
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(Settings)